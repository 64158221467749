import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import GatsbyImage from "gatsby-image";

const IndexPage = () => (
  <Layout>
    <SEO title="Rock The Rink" keywords={[`Rock The Rink`]} />
    <div class="container">
    <div class="rtr-event">
    <img src={require('../images/RTRposterUpdated.png')} />
    </div>
    <div class="text-info">
    <p><strong>Rock The Rink</strong> comes from the producers of the highly successful Thank You Canada tour. It’s an entertainment experience that highlights the world’s best figure skaters in an environment that appeals to all your senses.</p>
    <p>It’s an evening that takes you on a journey – at times, a nostalgic one.  It will also have you singing and dancing the night away with your favourite figure skating superstars.</p>
    <p>By integrating live music along with cutting edge sound, lighting, video and special effects, <strong>Rock The Rink</strong> takes a step beyond what you’re used to and provides you with an unforgettable experience.</p>
    <p><strong>Rock The Rink</strong> is an attitude. It is evocative. It makes you sing, dance, laugh, cry, move and be moved.  It will absolutely make you feel something.</p>
    <p>So, scroll down to your nearest city, click on the ticket link, and come help us <strong>Rock The Rink</strong>.</p>
    </div>
      <div id="upcoming-shows">
        <h1>Upcoming Shows</h1>
        <div class="show">
          <div class="show-details">
            <span class="location">Abbotsford, BC - Abbotsford Centre</span>
            <span class="date">Saturday October 5th</span>
            </div>
        <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/1100568836B77D7E" target="_blank" class="button">Purchase Tickets</a>
        </div>
          </div>
        <div class="show">
          <div class="show-details">
            <span class="location">Penticton, BC - South Okanagen Events Centre</span>
            <span class="date">Sunday October 6th</span>
            </div>
        <div class="ticket-links"><a href="https://ev6.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RINK&linkID=global-penticton&shopperContext=&caller=&appCode=&RSRC=WEB_PR&RDAT=ON" target="_blank" class="button">Purchase Tickets</a>
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Vancouver, BC - Thunderbird Arena</span>
            <span class="date">Monday October 7th</span>
            </div>
        <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/11005697F04E5B90" target="_blank" class="button">Purchase Tickets</a>
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Red Deer, AB - Westerner Park Centrium</span>
            <span class="date">Thursday October 10th</span>
            </div>
        <div class="ticket-links"><a href="https://www.ticketsalberta.com/event/rock-the-rink/" target="_blank" class="button">Purchase Tickets</a>
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Dawson Creek, BC - Encana Events Centre</span>
            <span class="date">Friday October 11th</span>
            </div>
          <div class="ticket-links"><a href="https://ev12.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS%3ADAWSON%3AEC19%3ARTR%3A&linkID=global-dawson&shopperContext=&pc=&caller=&appCode=&groupCode=&cgc=" target="_blank" class="button">Purchase Tickets</a>        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Prince George, BC - CN Centre</span>
            <span class="date">Saturday October 12th</span>
            </div>
          <div class="ticket-links"><a href="https://ev3.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RTR&linkID=pghc&shopperContext=&caller=&appCode=" target="_blank" class="button">Purchase Tickets</a>        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Grande Prairie, AB - Revolution Place</span>
            <span class="date">Sunday October 13th</span>
            </div>
          <div class="ticket-links"><a href="https://revolutionplace.tixit.ca/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=059BFB6A-2C90-409F-8EAE-4ABE1B4CBFE7&BOparam::WScontent::loadArticle::context_id=1B3B36D4-19A8-464F-84B9-483E6449787E" target="_blank" class="button">Purchase Tickets</a>        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Calgary, AB - Stampede Corral</span>
            <span class="date">Wednesday October 16th</span>
            </div>
          <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/1100568AC57E3F5A" target="_blank" class="button">Purchase Tickets</a>        
          </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Lethbridge, AB - ENMAX Centre</span>
            <span class="date">Thursday October 17th</span>
            </div>
        <div class="ticket-links"><a href="https://ev2.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS%3AMULTI%3ASE19%3ARR%3A&linkID=lethbridge-multi&shopperContext=&pc=&caller=&appCode=&groupCode=ESE&cgc=&dataAccId=517&locale=en_US&siteId=ev_lethbridge-multi&poolId=pac8-evcluster1&sDomain=ev2.evenue.net" target="_blank" class="button">Purchase Tickets</a>
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Regina, SK - Brandt Centre</span>
            <span class="date">Friday October 18th</span>
            </div>
            <div class="ticket-links"><a href="https://www1.ticketmaster.ca/event/11005691BF2B43C4" target="_blank" class="button">Purchase Tickets</a>        
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Medicine Hat, AB - Canalta Centre</span>
            <span class="date">Saturday October 19th</span>
            </div>
          <div class="ticket-links"><a href="https://www1.ticketmaster.ca/event/110056687C6023B8" target="_blank" class="button">Purchase Tickets</a>  
          </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Brandon, MB - Keystone Centre</span>
            <span class="date">Sunday October 20th</span>
            </div>
            <div class="ticket-links"><a href="https://ev2.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS:KEYSTONE:SE18:RTR:&linkID=keystone&RDAT=&RSRC" target="_blank" class="button">Purchase Tickets</a>  
          </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Sault Ste. Marie, ON - GFL Memorial Gardens</span>
            <span class="date">Tuesday October 29th</span>
            </div>
           <div class="ticket-links"><a href="https://ev8.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=S&linkID=global-steelback&shopperContext=&caller=&appCode=" target="_blank" class="button">Purchase Tickets</a>          
          </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">London, ON - Budweiser Gardens</span>
            <span class="date">Wednesday October 30th</span>
            </div>
          <div class="ticket-links"><a href="https://budweisergardens.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RTR&linkID=global-labatt&shopperContext=&caller=&appCode=" target="_blank" class="button">Purchase Tickets</a>          
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Sudbury, ON - Sudbury Arena</span>
            <span class="date">Friday November 1st</span>
            </div>
           <div class="ticket-links"><a href="https://linkprotect.cudasvc.com/url?a=https%3a%2f%2fpurchase.tickets.com%2fbuy%2fTicketPurchase%3fagency%3dSUDV_PLAGENCY%26orgid%3d46229%26pid%3d8708153&c=E,1,GFzn8ORhEyiy_0bvoBO6etjokag7zBX7Vk1L-qOc44TiBX3D7ttO5P4MAp1gyRaC1mNhlVpeiuHPoeGW8amOcbc4vBoPSbug3VlVeRFC6gI24w,,&typo=1" target="_blank" class="button">Purchase Tickets</a>         
           </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Kitchener, ON - Memorial Auditorium</span>
            <span class="date">Saturday November 2nd</span>
            </div>
            <div class="ticket-links"><a href="https://urldefense.proofpoint.com/v2/url?u=https-3A__kwtickets.evenue.net_cgi-2Dbin_ncommerce3_SEGetEventList-3FgroupCode-3DRTR-26linkID-3Dcits-26shopperContext-3D-26caller-3D-26appCode&d=DwMFAg&c=fMYCMpvyrCeAgEEhg2KwUA&r=4XAc6C3QPMEzTKGx-ylm0XQjolYXxyICWuaVcO-FItI&m=FCfzT2fufFRvoasC7Fm4wWXacF2qQ4uIPv_KZrCFnaE&s=I9pm6wVa9oiuQr-ehDOTnzcLWFWZaC52cwW7mGebY4c&e=" target="_blank" class="button">Purchase Tickets</a>         
           </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Kingston, ON - Leon's Centre</span>
            <span class="date">Sunday November 3rd</span>
            </div>
            <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/10005697DC2C6A30" target="_blank" class="button">Purchase Tickets</a>         
           </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Mississauga, ON - Paramount Fine Foods Centre</span>
            <span class="date">Wednesday November 6th</span>
            </div>
       <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/1000569A244B0865" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Ottawa, ON - TD Place</span>
            <span class="date">Thursday November 7th</span>
            </div>
        <div class="ticket-links"><a href="https://ev10.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RTR&linkID=ottawa67&shopperContext=&caller=&appCode=" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">St. Catharines, ON - Meridian Centre</span>
            <span class="date">Friday November 8th</span>
            </div>
        <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/10005684D2D34A96" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Oshawa, ON - Tribute Communities Centre</span>
            <span class="date">Saturday November 9th</span>
            </div>
        <div class="ticket-links"><a href="https://ev12.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RTR&linkID=global-oshawa&shopperContext=&caller=&appCode=&_template=chocolate" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Peterborough, ON - Peterborough Memorial Centre</span>
            <span class="date">Wednesday November 13th</span>
            </div>
        <div class="ticket-links"><a href="https://tickets.memorialcentre.ca/Online/default.asp?doWork::WScontent::loadArticle=Load&BOparam::WScontent::loadArticle::article_id=C44D4E10-EA5A-4E5F-B1C8-36BFF65580CB&sessionlanguage=&SessionSecurity::linkName" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Laval, QC - Place Bell</span>
            <span class="date">Thursday November 14th</span>
            </div>
        <div class="ticket-links"><a href="https://evenko.ca/en/events/24907/" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Quebec City, QC</span>
            <span class="date">Friday November 15th</span>
            </div>
       <div class="ticket-links"><a href="https://www1.ticketmaster.ca/event/3100568BE16C67E1" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Moncton, NB - Avenir Centre</span>
            <span class="date">Saturday November 16th</span>
            </div>
        <div class="ticket-links"><a href="https://www.ticketmaster.ca/event/31005688DBC17461" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
          <div class="show">
          <div class="show-details">
            <span class="location">Halifax, NS - Scotiabank Centre</span>
            <span class="date">Sunday November 17th</span>
            </div>
            <div class="ticket-links"><a href="https://ticketatlantic.evenue.net/cgi-bin/ncommerce3/SEGetEventList?groupCode=RTR&linkID=halifax" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>          
          <div class="show">
          <div class="show-details">
            <span class="location">St. Johns, NL - Mile One Centre</span>
            <span class="date">Saturday November 23rd</span>
            </div>
        <div class="ticket-links"><a href="https://ev10.evenue.net/cgi-bin/ncommerce3/SEGetEventInfo?ticketCode=GS%3AMOC%3AMOC19%3A191123RR%3A&linkID=moc&shopperContext=&pc=&caller=&appCode=&groupCode=E&cgc" target="_blank" class="button">Purchase Tickets</a>  
        </div>
          </div>
        </div> 
      </div>  
  </Layout>
)
export default IndexPage
